@charset "UTF-8";

$dark-background: #292a2d;
$dark-background-secondary: #3b3d42;
$dark-color: #a9a9b3;
$dark-color-secondary: #73747b;
$dark-border-color: #4a4b50;

$media-size-phone: "(max-width: 684px)";
$media-size-tablet: "(max-width: 900px)";

:root {
  --phoneWidth: (max-width: 684px);
  --tabletWidth: (max-width: 900px);
}