// HEADER
.header {
  background: #252627;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px;

  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media #{$media-size-phone} {
      flex-direction: row-reverse;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 760px;
    max-width: 100%;
  }
}

.theme-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  cursor: pointer;
}

.theme-toggler {
  fill: currentColor;
}

.unselectable {
  user-select: none;
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
}

// MENU
.menu {
  background: #252627;
  z-index: 9999;

  @media #{$media-size-phone} {
    position: absolute;
    top: 50px;
    right: 0;
    border: none;
    margin: 0;
    padding: 10px;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    font-size: 1rem;
    list-style: none;

    li {
      margin: 0 12px;
    }

    @media #{$media-size-phone} {
      flex-direction: column;
      align-items: flex-start;
      padding: 0;

      li {
        margin: 0;
        padding: 5px;
      }
    }
  }

  &-trigger {
    width: 24px;
    height: 24px;
    fill: currentColor;
    margin-left: 10px;
    cursor: pointer;
  }

  a {
    display: inline-block;
    margin-right: 15px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

// LOGO
.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  font-display: auto;
  font-family: monospace, monospace;

  img {
    height: 44px;
  }

  &__mark {
    margin-right: 5px;
  }

  &__text {
    font-size: 1.125rem;
  }

  &__cursor {
    display: inline-block;
    width: 10px;
    height: 1rem;
    background: #fe5186;
    margin-left: 5px;
    border-radius: 1px;
    animation: cursor 1s infinite;
  }

  @media (prefers-reduced-motion: reduce) {
    &__cursor {
        animation: none;
    }
  }

}

@keyframes cursor {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}
